
/* injects from baggage-loader */

import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import * as React from 'react';
import i18next from 'i18next';
import {Trans} from 'react-i18next';

import {ErrorBoundary} from '../common/components/ErrorBoundary';
import {MODALS, setModal} from '../../actions/modal';
import {UserLoginForm} from '../common/components/forms/UserLoginForm';
import {isAuthenticated} from '../../selectors/user';
import {loginUser} from '../../actions/user';
import {Logo} from '../common/components/site/Logo';
import {HelmetTitleHOC} from '../common/components/utils/HelmetTitleHoc';
import {createLoadingSelector} from '../../selectors/loading';

interface ILoginPageContainer {
    loginUser: (username: string, password: string, rememberMe: boolean) => void;
    setModal: (modalType: MODALS) => void;
}

interface ILoginPageContainerProps {
    isAuthenticated: boolean;
    isGettingUser: boolean;
}


class LoginPageContainer extends React.Component<ILoginPageContainer & ILoginPageContainerProps> {

    remindPassword = () => {
        this.props.setModal(MODALS.REMIND_PASSWORD);
    };

    onLoginSubmit = (values) => {
        this.props.loginUser(values.email, encodeURIComponent(values.password), values.rememberMe || false);
    };

    render() {
        return (
            <>
                <HelmetTitleHOC title={i18next.t('Prisijungimas')}/>
                <Helmet>
                    <meta name='robots' content='noindex'/>
                    <link rel='canonical' href={window.location.href}/>
                    <meta property='og:image' content='/assets/images/kakava-social.png'/>
                </Helmet>
                {!this.props.isGettingUser &&
                <section id='main' className='page-box-login'>
                  <ErrorBoundary>
                      {!this.props.isAuthenticated &&
                      <div className='box-login-container'>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-12 col-lg-6 px-lg-4 text-center'>
                              <div>
                                <Logo className='logo'/>
                              </div>
                              <img
                                className='visual d-lg-none img-fluid mt-3 mb-5'
                                src='/assets/images/login-visual-box-mobile.png'
                                alt='login'
                              />
                              <img
                                className='visual d-none d-lg-block img-fluid mt-3'
                                src='/assets/images/register-visual.png'
                                alt='register'
                              />
                            </div>
                            <div className='col-12 col-lg-6 px-lg-4'>
                              <div className='login-form-container'>
                                <h2>
                                  <Trans>Prisijunk</Trans>
                                </h2>
                                <UserLoginForm
                                  onSubmit={this.onLoginSubmit}
                                  remindPassword={this.remindPassword}
                                  initialValues={{rememberMe: true}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      }
                  </ErrorBoundary>
                </section>
                }
            </>
        );
    }
}

const loginSelector = createLoadingSelector(['LOGIN', 'GET_CURRENT_USER']);
const mapStateToProps = (state) => {
    return {
        isGettingUser: loginSelector(state),
        isAuthenticated: isAuthenticated(state)
    };
};

const mapDispatchToProps = {
    loginUser,
    setModal
};

const LoginPageContainerConnected = connect<ILoginPageContainerProps, ILoginPageContainer, {}>(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
export {LoginPageContainerConnected as LoginPageContainer};
